//
//
//
//

import pageMixin from '$mixins/page-mixin.js';
import StepLayout from '../cuota-personalizada/steps/step-layout.vue';
import { createLead } from '$network/api.js';

export default {
  name: 'LeadRedirect',
  components: {
    StepLayout
  },
  mixins: [pageMixin],
  data: () => ({}),
  async created() {
    const { channel, agentIdentification } = this.$route.query;

    window.localStorage.setItem('channel', channel);

    const lead = await this.createLead(channel, agentIdentification);

    this.$router.push({
      name: 'Cuota personalizada',
      params: {
        uid: lead.data.uid
      }
    });
  },
  methods: {
    async createLead(channel, agentIdentification) {
      const { data } = await createLead(channel, agentIdentification);
      return data;
    }
  }
};
